import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import {EventmitterService} from './eventmitter.service';
import {User} from '../models/user.model';
import { AngularFireAuth } from  "@angular/fire/auth";


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public  afAuth:  AngularFireAuth,
    public router : Router   
  ) { }  

  public login(objData:User){
    return this.afAuth.signInWithEmailAndPassword(objData.email, objData.password);
  }  

  public logout(){
    this.afAuth.signOut();    
    localStorage.clear();
    this.router.navigate(['auth/login/'])
  }
}
