<div class="header-container">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
        <a style="border: none;" class="logo" [routerLink]="['/pages/notification']" (click)="navigateHome()">Byebye <span>admin</span></a>
    </div>
    <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container">
    <nb-actions size="small">

        <!-- <nb-action class="control-item">
            <nb-search type="rotate-layout"></nb-search>
        </nb-action>
        <nb-action class="control-item" icon="email-outline"></nb-action>
        <nb-action class="control-item" icon="bell-outline"></nb-action> -->
        <nb-action class="user-action" *nbIsGranted="['view', 'user']">
            <nb-user [nbContextMenu]="userMenu" nbContextMenuTag="my-context-menu" [onlyPicture]="userPictureOnly" [name]="loginUsername" [picture]="user?.picture">
            </nb-user>
        </nb-action>
    </nb-actions>
</div>