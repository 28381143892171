import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private _router:Router){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (state && state.url.includes('/pages')) {
        if (localStorage.getItem('isLoggedIn') == 'true') {
          return true
        }
        else {
          this._router.navigateByUrl('/auth');
        }
      }
      else {
        if (localStorage.getItem('isLoggedIn') == 'true') {
          this._router.navigateByUrl('/pages/notification/');
        }
        else {
          return true;
        }
      }
  }

  public isLoggedIn(): boolean {
    let status = false;
    if (localStorage.getItem('isLoggedIn') == "true") {
      status = true;
    }
    else {
      status = false;
    }
    return status;
  }

  
}
